
import PropTypes from 'prop-types'
import React from 'react'
import styles from './events.module.css'
import SEO from '../components/seo'
import FilmpageEvents from '../components/filmpage-events'


export const FilmListTemplate = ({ films, locations, }) => {

    return (
        <>
            <SEO
                title={"Movies showing now Singapore | New & latest movies Singapore"}
                description={"The latest movies showing now in The Projector cinema. Movie showtimes and buy movie tickets for current and new movies, indie, cult classics & foreign movies."}
            />
            <div className="container flex flex-col items-stretch">
                <h1 className={styles.title}>
                    Movies Showtimes
                </h1>

                <FilmpageEvents
                    events={films}
                    locations={locations}
                />

            </div>
        </>
    )
}

FilmListTemplate.propTypes = {
    films: PropTypes.arrayOf(PropTypes.object),
}
