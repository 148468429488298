import React from 'react'
import PropTypes from 'prop-types'

import styles from './arrow-button.module.css'
import { classes } from '../util/components'

const ArrowButton = ({ className, onClick, disabled, direction = 'left' }) => (
  <button
    className={classes(
      styles.arrowButton,
      styles[direction],
      className,
      disabled && styles.disabled
    )}
    aria-label={direction === 'left' ? 'Previous' : 'Next'}
    onClick={(!disabled && onClick) || undefined}
  >
    <span className={styles.arrowWrap}>
      <svg
        width="10px"
        height="16px"
        viewBox="0 0 10 16"
        version="1.1"
        className={styles.arrow}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-9.000000, 0.000000)"
            fillRule="nonzero"
            stroke="currentColor"
            strokeWidth="1"
          >
            <polyline
              transform="translate(13.500000, 8.000000) rotate(-90.000000) translate(-13.500000, -8.000000) "
              points="6.5 4.5 13.5 11.5 20.5 4.5"
            ></polyline>
          </g>
        </g>
      </svg>
    </span>
  </button>
)

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
}

export default ArrowButton
