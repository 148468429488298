import dayjs from 'dayjs'
import * as pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'
import { keyBy } from 'lodash'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import { range } from '../util/array'
import DateFilter from './date-filter'
import EventList from './event-list'
import EventTypeFilter from './event-type-filter'
import LocationFilter from './location-filter'
import EventsByDate from './events-by-date'
import styles from './homepage-events.module.css'
import ThemeBanner from './theme-banner'
import { groupBy } from '../util/array'
import FilmList from '../components/film-list'
import FilmsByDate from '../components/films-by-date'

const FilmpageEventsByDate = ({
    veeziWebSessions,
    dates,
    events,
    screenToLocation,
    locationFilter
}) => {


    if (
        !(events || []).filter(e => !!e.startDate).length &&
        !(veeziWebSessions || []).length
    ) {
        return <>
            <div className="text-center">
                <h3 className={styles.eventTypeHeader}>{locationFilter}</h3>
            </div>
            <div className={styles.noEvents}>Check back soon for updates.</div>
        </>

    }

    return (
        <>
            <div className="text-center">
                <h3 className={styles.eventTypeHeader}>{locationFilter}</h3>
            </div>
            <FilmsByDate
                events={events}
                veeziWebSessions={veeziWebSessions}
            />
        </>
    )
}

const FilmpageEventsByType = ({
    veeziWebSessions,
    date,
    events,
    screenToLocation,
    locationFilter
}) => {


    const films = []
    const otherEvents = []
    events.forEach(event => {
        const sessions = event.veeziFilmId
            ? veeziWebSessions.filter(
                s =>
                    s.FilmId === event.veeziFilmId &&
                    dayjs(s.PreShowStartTime).isSame(date, 'day')
            )
            : []

        if (
            !sessions.length &&
            (!event.startDate || !dayjs(event.startDate).isSame(date, 'day'))
        ) {
            return
        }

        if (event.veeziFilmId) {
            event.sessions = sessions
        }

        if (event.eventTypes.includes('film')) {
            films.push(event)
        }

        if (event.eventTypes.includes('event')) {
            otherEvents.push(event)
        }
    })

    if (!films.length && !otherEvents.length) {
        return <>
            <div className="text-center">
                <h3 className={styles.eventTypeHeader}>{locationFilter}</h3>
            </div>
            <div className={styles.noEvents}>Check back soon for updates.</div>
        </>
    }
    return (
        <>
            {films.length ? (
                <div className={styles.films}>
                    <div className="text-center">
                        <h3 className={styles.eventTypeHeader}>{locationFilter}</h3>
                    </div>
                    <FilmList events={films} listStyle="list" type="ads" screenToLocation={screenToLocation} locationFilter={locationFilter} />

                </div>
            ) : null}
        </>
    )
}

const FilmpageEvents = ({ events, locations }) => {

    const currentDay = dayjs().startOf('day')
    const [selected, setSelected] = useState('all')
    const [dateRange, setDateRange] = useState(
        range(0, 6).map(d =>
            dayjs(currentDay)
                .add(d, 'day')
                .startOf('day')
                .toDate()
        )
    )
    const [isLoader, setLoader] = useState(true)

    const veeziWebSessions = useVeeziWebSessionsAll({})

    const groupByLocations = groupBy(veeziWebSessions, s => {
        const location = locations.find(l => l.screens.includes(s.ScreenId))
        if (location) return location.title

        // All location will temporarily default to Golden Mile Tower for now
        return 'Golden Mile Tower'
    })
    const eventTypeFilter = 'film'

    const locationFilter1 = 'Golden Mile Tower'
    const locationFilter2 = 'Cineleisure'
    // const locationFilter3 = 'Picturehouse Xtra'
    // const locationFilter4 = 'Riverside Point'

    let veeziData1 = groupByLocations[locationFilter1] || []
    let veeziData2 = groupByLocations[locationFilter2] || []
    // let veeziData3 = groupByLocations[locationFilter3] || []
    // let veeziData4 = groupByLocations[locationFilter4] || []

    useEffect(() => {
        if (Object.keys(groupByLocations).length !== 0) {
            setLoader(false)
        }
    }, [
        veeziData1, 
        veeziData2, 
        // veeziData3, 
        // veeziData4,
    ])

    const filteredEvents = useMemo(() => {
        const eventTypePredicate = e => e.eventTypes.includes(eventTypeFilter)

        const eventDatePredicate = e =>
            (!e.startDate && e.veeziFilmId) ||
            dayjs(e.startDate).isBetween(dateRange[0], dateRange[6], 'day', '[]')

        return (events || []).filter(
            e => eventTypePredicate(e) && eventDatePredicate(e)
        )
    }, [events, dateRange, eventTypeFilter])

    const filteredEventsKeyBy = keyBy(filteredEvents, 'veeziFilmId')

    const filteredSessions1 = useMemo(
        () =>
            (veeziData1 || []).filter(
                s =>
                    filteredEventsKeyBy[s.FilmId] &&
                    (!s.PreShowStartTime ||
                        dayjs(s.PreShowStartTime).isBefore(
                            dayjs(dateRange[6]).endOf('day')
                        ))
            ),
        [veeziData1, dateRange, filteredEvents]
    )


    const filteredSessions2 = useMemo(
        () =>
            (veeziData2 || []).filter(
                s =>
                    filteredEventsKeyBy[s.FilmId] &&
                    (!s.PreShowStartTime ||
                        dayjs(s.PreShowStartTime).isBefore(
                            dayjs(dateRange[6]).endOf('day')
                        ))
            ),
        [veeziData2, dateRange, filteredEvents]
    )
    // const filteredSessions3 = useMemo(
    //     () =>
    //         (veeziData3 || []).filter(
    //             s =>
    //                 filteredEventsKeyBy[s.FilmId] &&
    //                 (!s.PreShowStartTime ||
    //                     dayjs(s.PreShowStartTime).isBefore(
    //                         dayjs(dateRange[6]).endOf('day')
    //                     ))
    //         ),
    //     [veeziData3, dateRange, filteredEvents]
    // )
    // const filteredSessions4 = useMemo(
    //     () =>
    //         (veeziData4 || []).filter(
    //             s =>
    //                 filteredEventsKeyBy[s.FilmId] &&
    //                 (!s.PreShowStartTime ||
    //                     dayjs(s.PreShowStartTime).isBefore(
    //                         dayjs(dateRange[6]).endOf('day')
    //                     ))
    //         ),
    //     [veeziData4, dateRange, filteredEvents]
    // )


    const validEvents = (events || []).filter(
        e => e.eventTypes.includes(eventTypeFilter)
    )

    const validEventIds = validEvents.map(e => e.veeziFilmId).filter(f => !!f)

    const validSessions1 = veeziData1.filter(s =>
        validEventIds.includes(s.FilmId)
    )
    const validSessions2 = veeziData2.filter(s =>
        validEventIds.includes(s.FilmId)
    )
    // const validSessions3 = veeziData3.filter(s =>
    //     validEventIds.includes(s.FilmId)
    // )
    // const validSessions4 = veeziData4.filter(s =>
    //     validEventIds.includes(s.FilmId)
    // )

    const datesWithSessions1 = validSessions1.reduce((dates, s) => {
        if (s.PreShowStartTime)
            dates.push(
                dayjs(s.PreShowStartTime)
                    .startOf('day')
                    .toString()
            )
        return dates
    }, [])
    const datesWithSessions2 = validSessions2.reduce((dates, s) => {
        if (s.PreShowStartTime)
            dates.push(
                dayjs(s.PreShowStartTime)
                    .startOf('day')
                    .toString()
            )
        return dates
    }, [])
    // const datesWithSessions3 = validSessions3.reduce((dates, s) => {
    //     if (s.PreShowStartTime)
    //         dates.push(
    //             dayjs(s.PreShowStartTime)
    //                 .startOf('day')
    //                 .toString()
    //         )
    //     return dates
    // }, [])
    // const datesWithSessions4 = validSessions4.reduce((dates, s) => {
    //     if (s.PreShowStartTime)
    //         dates.push(
    //             dayjs(s.PreShowStartTime)
    //                 .startOf('day')
    //                 .toString()
    //         )
    //     return dates
    // }, [])



    const datesWithEvents = validEvents.reduce((dates, e) => {
        if (e.startDate)
            dates.push(
                dayjs(e.startDate)
                    .startOf('day')
                    .toString()
            )
        return dates
    }, [])


    const allowedDates = [
        ...new Set([
            ...datesWithSessions1, 
            ...datesWithSessions2,
            // ...datesWithSessions3,
            // ...datesWithSessions4,
            ...datesWithEvents
        ]),
    ].map(d => dayjs(d).toDate())


    const screenToLocation = {}
    for (const item of locations) {
        for (const screen of (item.screens || [])) {
            screenToLocation[screen] = item.title
        }
    }



    return (
        <div className={styles.homepageEvents}>
            <DateFilter
                className={styles.dateFilter}
                ariaControls="homepageEventsResults"
                dateButtonClassName={styles.dateButton}
                filterButtonClassName={styles.filterButton}
                showCalendarWeeks={false}
                onChange={(data) => {
                    setTimeout(() => {
                        typeof googletag !== 'undefined' && googletag.pubads().refresh();
                    }, 1000)
                    setSelected(data)
                }}
                onRangeChange={r => setDateRange(r)}
                value={selected}
                allowedDates={allowedDates}
                showAll={true}
            />
            <div className={styles.themeBanner}>
                <ThemeBanner date={selected} />
            </div>
            {isLoader ?
                <div className={styles.noEvents}>LOADING...</div>
                :
                <div id="homepageEventsResults" role="region" aria-live="polite">
                    {selected === 'all' ? (
                        <>
                            <FilmpageEventsByDate
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions1}
                                dates={dateRange}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter1}
                            />
                            <FilmpageEventsByDate
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions2}
                                dates={dateRange}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter2}
                            />
                            {/* <FilmpageEventsByDate
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions3}
                                dates={dateRange}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter3}
                            />
                            <FilmpageEventsByDate
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions4}
                                dates={dateRange}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter4}
                            /> */}
                        </>
                    ) : (
                        <>
                            <FilmpageEventsByType
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions1}
                                date={selected}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter1}
                            />
                            <FilmpageEventsByType
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions2}
                                date={selected}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter2}
                            />
                            {/* <FilmpageEventsByType
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions3}
                                date={selected}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter3}
                            />
                            <FilmpageEventsByType
                                events={filteredEvents}
                                veeziWebSessions={filteredSessions4}
                                date={selected}
                                screenToLocation={screenToLocation}
                                locationFilter={locationFilter4}
                            /> */}
                        </>
                    )}
                </div>
            }
        </div>
    )
}

export default FilmpageEvents
