import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
    memo,
    useLayoutEffect,
  } from 'react'
  import dayjs from 'dayjs'
  import sortBy from 'lodash/sortBy'
  import { Bling as GPT } from "react-gpt";
  
  import { classes } from '../util/components'
  import { useScreenSize } from '../hooks/screen-size'
  import styles from './events-by-date.module.css'
  import { groupBy } from '../util/array'
  import EventList from './event-list'
  import throttle from '../util/throttle'
  import FilmList from './film-list';
  
  const DateGroupHeader = memo(({ fixedTop, dateStyle, date }) => {
    const dateDay = dayjs(date)
    return (
      <div className={styles.dateGroupHeader}>
        <div style={fixedTop} className={`${styles.dateGroupDate} ${dateStyle}`}>
          <span className={styles.date}>{dateDay.format('D')}</span>
          <span className={styles.month}>{dateDay.format('MMM')}</span>
          <span className={styles.weekday}>{dateDay.format('ddd')}</span>
        </div>
      </div>
    )
  })
  
  const getDateGroupsFromEvents = (events, veeziWebSessions) => {
    const dateGroups = {}
    const webSessionsByEvent = groupBy(veeziWebSessions || [], s => s.FilmId)
    const dateToGroupKey = date => dayjs(date).format('YYYY-MM-DD')
  
    events.forEach(event => {
      if (event.veeziFilmId) {
        const sessions = webSessionsByEvent[event.veeziFilmId]
  
        if ((sessions || []).length) {
          for (const session of sessions) {
            dateGroups[dateToGroupKey(session.PreShowStartTime)] =
              dateGroups[dateToGroupKey(session.PreShowStartTime)] || []
  
            const existingEvent = dateGroups[
              dateToGroupKey(session.PreShowStartTime)
            ].find(e => e.veeziFilmId === event.veeziFilmId)
  
            if (existingEvent) {
              existingEvent.sessions = [...existingEvent.sessions, session]
            } else {
              dateGroups[dateToGroupKey(session.PreShowStartTime)] = [
                ...dateGroups[dateToGroupKey(session.PreShowStartTime)],
                { ...event, sessions: [session] },
              ]
            }
          }
          return
        }
      }
  
      if (event.startDate) {
        dateGroups[dateToGroupKey(event.startDate)] = [
          ...(dateGroups[dateToGroupKey(event.startDate)] || []),
          event,
        ]
      }
    })
  
    Object.keys(dateGroups).forEach(date => {
      dateGroups[date] = sortBy(dateGroups[date], ({ startDate, sessions }) =>
        startDate
          ? dayjs(startDate).unix()
          : Math.min(sessions.map(s => dayjs(s.PreShowStartTime).unix()))
      )
    })
  
    return dateGroups
  }
  
  const EventsByDate = ({
    className,
    veeziWebSessions,
    events,
    showEventTypes,
    type = "other",
    screenToLocation,
    locationFilter,
  }) => {
    const { screenSize, mainNavCollapsedHeight } = useScreenSize()
    const dateGroups = useMemo(
      () => getDateGroupsFromEvents(events, veeziWebSessions),
      [events, veeziWebSessions]
    )
    const dateKeys = useMemo(() => Object.keys(dateGroups).sort(), [dateGroups])
    const parentEl = useRef(null)
    const dateRefs = useRef(dateKeys.map(() => React.createRef()))
  
    const bottomOffset = useRef(null)
    const dateHeights = useRef([])
    const dateOffsetTops = useRef([])
    const currentTop = useRef({})
    const [currentIndex, setCurrentIndex] = useState(-1)
  
    dateRefs.current = dateKeys.map(() => React.createRef())
  
    useLayoutEffect(() => {
      const scrollY = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )
  
      bottomOffset.current =
        parentEl.current.offsetTop + parentEl.current.offsetHeight
      dateHeights.current = dateRefs.current.map(
        d => d.current && d.current.offsetHeight
      )
      dateOffsetTops.current = dateRefs.current.map(
        d => d.current && d.current.getBoundingClientRect().top + scrollY
      )
    }, [dateGroups])
  
    const callback = useCallback(
      throttle(() => {
        const scrollY = Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        )
  
        const currentScroll = scrollY + mainNavCollapsedHeight
  
        let index = -1
  
        dateKeys.forEach((d, i) => {
          const isScrolledPast = currentScroll > dateOffsetTops.current[i]
          if (isScrolledPast) {
            index = i
          }
        })
  
        if (index === dateKeys.length - 1) {
          const belowParents =
            scrollY >
            bottomOffset.current -
            10 -
            dateHeights.current[dateHeights.current.length - 1] -
            mainNavCollapsedHeight
  
          if (belowParents) {
            index = dateKeys.length
          }
        }
  
        // -20 due to margin-top: 40px on mobile view
        if (currentIndex < dateKeys.length) {
          currentTop.current = {
            top: mainNavCollapsedHeight - 40 + 'px',
          }
        } else {
          currentTop.current = {}
        }
  
        setCurrentIndex(index)
      }, 20),
      [dateKeys, mainNavCollapsedHeight]
    )
  
    const getStyles = useCallback(
      index => {
        if (index <= currentIndex) {
          return 'invisible'
        }
  
        return ''
      },
      [currentIndex]
    )
  
    const getFixedStyles = useCallback(() => {
      if (currentIndex >= 0) {
        if (currentIndex === dateKeys.length) {
          return styles.dateGroupAbsolute
        }
  
        return styles.dateGroupFixed
      }
  
      return `invisible ${styles.dateGroupFixed}`
    }, [currentIndex, dateKeys])
  
    useEffect(() => {
      if (['lg', 'xl'].includes(screenSize)) return
  
      window.addEventListener('scroll', callback)
  
      return () => {
        window.removeEventListener('scroll', callback)
      }
    }, [callback, screenSize])
  
    const renderView = []
    dateKeys.forEach((item, index) => {
      renderView.push(<div className={styles.dateGroup} key={index}>
        <div className={styles.dateGroupHeader}>
          <div ref={dateRefs.current[index]}>
            <DateGroupHeader dateStyle={getStyles(index)} date={item} />
          </div>
        </div>
        <div className={styles.dateGroupEvents}>
          <FilmList
            events={dateGroups[item]}
            listStyle="list"
            showEventTypes={showEventTypes}
            screenToLocation={screenToLocation}
            locationFilter={locationFilter}
          />
        </div>
      </div>)
      if (type === 'ads' && index === 0) {
        renderView.push(<GPT
          key="ads-1"
          style={{
            justifyContent: 'center',
            marginBottom: '20px',
          }}
          className="ads-1"
          adUnitPath="/21684927889/homepage_projector/div-gpt-ad-3302321-1"
          sizeMapping={[
            { viewport: [0, 0], slot: [320, 320] },
            { viewport: [970, 0], slot: [970, 250] },
          ]}
          collapseEmptyDiv={true}
        />)
      }
    })
  
    return (
      <div className={classes(className, styles.eventsByDate)} ref={parentEl}>
        <div className={styles.dateGroupHeader}>
          <DateGroupHeader
            fixedTop={currentTop.current}
            dateStyle={getFixedStyles()}
            date={dateKeys[Math.min(currentIndex, dateKeys.length - 1)]}
          />
        </div>
        {renderView}
        {/* {dateKeys.map((k, i) => {
          return (
            <div className={styles.dateGroup} key={i}>
              <div className={styles.dateGroupHeader}>
                <div ref={dateRefs.current[i]}>
                  <DateGroupHeader dateStyle={getStyles(i)} date={k} />
                </div>
              </div>
              <div className={styles.dateGroupEvents}>
                <EventList
                  events={dateGroups[k]}
                  listStyle="list"
                  showEventTypes={showEventTypes}
                />
              </div>
            </div>
          )
        })} */}
      </div>
    )
  }
  
  export default EventsByDate
  