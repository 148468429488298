import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import slugify from 'slugify'

import Link from '../components/link'
import useNoSessionMessage from '../hooks/useNoSessionMessage'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import getReleasingSchedules from '../util/releasingSchedules'
import styles from './event-list-item.module.css'
import styles2 from './film-list-item.module.css'
import EventType from './event-type'
import Image from './image'
import Modal from './modal'
import Rating from './rating'
import TicketBooking from './ticket-booking'
import TicketBookingPreview from './ticket-booking-preview'

const TIME_FORMAT = 'HH:mm'

const getDateText = event => {
  if (!event.startDate) return null

  const isMultidayEvent =
    !!event.startDate &&
    !!event.endDate &&
    dayjs(event.endDate).diff(event.startDate, 'hours') > 12

  const start = dayjs(event.startDate)

  if (isMultidayEvent) {
    const end = dayjs(event.endDate)
    if (start.month === end.month) {
      return (
        <>
          <time dateTime={start.toISOString()}>{start.format('D')}</time>
          {' - '}
          <time dateTime={end.toISOString()}>{end.format('D MMM YYYY')}</time>
        </>
      )
    } else if (start.year === end.year) {
      return (
        <>
          <time dateTime={start.toISOString()}>{start.format('D MMM')}</time>
          {' - '}
          <time dateTime={end.toISOString()}>{end.format('D MMM YYYY')}</time>
        </>
      )
    } else {
      return (
        <>
          <time dateTime={start.toISOString()}>
            {start.format('D MMM YYYY')}
          </time>
          {' - '}
          <time dateTime={end.toISOString()}>{end.format('D MMM YYYY')}</time>
        </>
      )
    }
  } else if (event.isAllDay) {
    return (
      <time dateTime={start.toISOString()}>{start.format('D MMM YYYY')}</time>
    )
  }

  return (
    <time dateTime={start.toISOString()}>
      {start.format('D MMM YYYY h.mma')}
    </time>
  )
}

const CallToAction = ({
  event,
  onClick,
  onVaxxedClick,
  screenToLocation,
  locationFilter,
}) => {

  const eventSessions =
    event.sessions && event.sessions.length
      ? [...event.sessions].sort(
        (a, b) => new Date(a.PreShowStartTime) - new Date(b.PreShowStartTime)
      )
      : []

  const locationEvent = {}
  if (screenToLocation) {
    for (const item of (eventSessions || [])) {
      const locationName = screenToLocation[item.ScreenId] || 'Golden Mile Tower'
      if (!locationEvent[locationName]) {
        locationEvent[locationName] = [item]
      } else {
        locationEvent[locationName].push(item)
      }
    }
  }

  // show link even if the url is missing, this is to cater
  // for "coming soon" CTA
  const showVodLink =
    (event.platforms || []).includes('vod') &&
    (event.vodURL || event.vodCallToAction)

  if (locationFilter == 'Everywhere') {
    return (<div className={styles.locationRow}>
      {Object.keys(locationEvent).sort().map((key, index) =>
        <div style={{ width: '100%' }} key={key}>
          {/* location div */}
          <div className={styles.locationTitle}>{key}</div>
          <div className={styles.buttons}>
            {locationEvent[key].map((s, i) =>
              !s.TicketsSoldOut ? (
                <a
                  key={i}
                  className={`${styles.button} ${s.FewTicketsLeft ? styles.btnSellingFast : styles.btnBuy
                    }`}
                  aria-label={`Book tickets for ${event.title} at ${dayjs(
                    s.PreShowStartTime
                  ).format(TIME_FORMAT)}`}
                  onClick={() => {
                    if ((s.accessibility || []).includes('vaxxed')) {

                      onVaxxedClick(s)
                    } else {

                      typeof window !== 'undefined' && window.open(s.Url, '_blank')
                    }
                  }}
                  rel="noopener noreferrer"
                >
                  {(s.accessibility || []).includes('vaxxed') ?
                    <span className={styles.vaxxed}>Vaxxed Hall</span> : null
                  }
                  <span className={styles.label}>
                    {s.FewTicketsLeft ? 'Selling Fast' : 'Buy'}
                  </span>
                  <span className={styles.time}>
                    {dayjs(s.PreShowStartTime).format(TIME_FORMAT)}
                  </span>
                </a>
              ) : (
                <div key={i} className={`${styles.button} ${styles.btnSoldOut}`}>
                  <span className={styles.label}>Sold Out</span>
                  <span className={styles.time}>
                    {dayjs(s.PreShowStartTime).format(TIME_FORMAT)}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>)
  }

  if (eventSessions.length) {
    return (<div className={styles2.filmBuyBtnMain}>
      {eventSessions.map((s, i) =>
        !s.TicketsSoldOut ? (
          <a
            key={i}
            className={`${styles2.buyButton} ${s.FewTicketsLeft ? styles.btnSellingFast : styles.btnBuy
              }`}
            aria-label={`Book tickets for ${event.title} at ${dayjs(
              s.PreShowStartTime
            ).format(TIME_FORMAT)}`}
            onClick={() => {
              if ((s.accessibility || []).includes('vaxxed')) {
                onVaxxedClick(s)
              } else {
                typeof window !== 'undefined' && window.open(s.Url, '_blank')
              }
            }}
            rel="noopener noreferrer"
          >
            {(s.accessibility || []).includes('vaxxed') ?
              <span className={styles2.vaxxed}>Vaxxed Hall</span> : null
            }
            <span className={styles2.label}>
              {s.FewTicketsLeft ? 'Selling Fast' : 'Buy'}
            </span>
            <span className={styles2.time}>
              {dayjs(s.PreShowStartTime).format(TIME_FORMAT)}
            </span>
          </a>
        ) : (
          <div key={i} className={`${styles2.buyButton} ${styles2.btnSoldOut}`}>
            <span className={styles2.label}>Sold Out</span>
            <span className={styles2.time}>
              {dayjs(s.PreShowStartTime).format(TIME_FORMAT)}
            </span>
          </div>
        )
      )}
    </div>)
  }

  return <div className={styles.noSessions}>
    {event.bookingURL ? (
      <button to={event.bookingURL} className={styles.btnNoSessions} onClick={() => {
        typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
          event_category: `Buy Tickets`,
          event_label: 'Projector',
          name: event.title,
        })
        typeof window !== "undefined" && window.open(event.bookingURL, '_blank')
      }}>
        {event.callToAction || 'Buy Tickets'}
      </button>
    ) : (
      event.veeziFilmId && (
        <button className={styles.btnNoSessions} onClick={(eventAction) => {
          typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
            event_category: `Buy Tickets`,
            event_label: 'Projector',
            name: event.title,
          })
          onClick(eventAction)
        }}>
          {event.callToAction || 'Buy Tickets'}
        </button>
      )
    )}
    {showVodLink ? (
      <Link to={event.vodURL || event.slug} className={styles.btnNoSessions}>
        {event.vodCallToAction || 'Rent Now'}
      </Link>
    ) : null}
  </div>
}

const FilmListItem = ({
  event,
  className,
  extraFilter,
  showEventType,
  itemStyle = 'row',
  showTicketBooking = true,
  isPreview = false,
  screenToLocation,
  locationFilter,
}) => {
  const eventDate = event.startDate ? (
    <div className=''>{getDateText(event)}</div>
  ) : null
  const veeziWebSessions = useVeeziWebSessionsAll({
    filmId: event.veeziFilmId,
  }).filter(extraFilter || (() => true))
  const noSessionMessage = useNoSessionMessage()
  const [isTicketOpen, setIsTicketOpen] = useState(false)
  const [isVaxxedOpen, setIsVaxxedOpen] = useState(false)
  const [selectedSession, setSelectedSession] = useState(null)
  const releasingSchedule = getReleasingSchedules(event.releasingSchedules)

  return (
    <>
      <div className={styles2.filmListItemMain}>
        <div className={styles2.filmCoverImage}>
          <Link to={event.slug} aria-label={`Read more about ${event.title}`}>
            {event.coverImage && event.coverImage.childImageSharp ? (
              <Image
                className={``}
                fluid={event.coverImage.childImageSharp.fluid}
              />
            ) : null}
          </Link>
          {/* {(event.themes || []).length ? (
            <Link className={styles.theme} to={event.themes[0].slug}>
              {event.themes[0].title}
            </Link>
          ) : null} */}
          {/* {event.releasingSchedules && event.releasingSchedules.length ? (
            <Link
              className={styles.alert}
              to={`/releasing-schedules/${slugify(releasingSchedule)}`}
            >
              {releasingSchedule}
            </Link>
          ) : null} */}
        </div>
        <div className={styles2.filmContentMain}>
          <div className={styles2.filmContentInner}>
            <h3 >
              <Link to={event.slug}>{event.title}</Link>
              {showEventType ? <EventType event={event} /> : null}
            </h3>
            <p className={styles2.filmCategories}>
              {(event.categories || []).length
                ? event.categories.map((c, i) => (
                  <span key={i}>
                    {c.title}
                  </span>
                ))
                : null}
            </p>
            {event.rating ? <p className={styles2.rating}> <Rating>{event.rating}</Rating></p> : null}
            <p className={styles2.description}>DESCRIPTION:</p>
            <div
              className={styles2.blurbHtml}
              dangerouslySetInnerHTML={{ __html: event.blurbHtml }}
            ></div>
          </div>
          {eventDate}
          <CallToAction event={event} onClick={() => setIsTicketOpen(true)}
            onVaxxedClick={(val) => {
              setSelectedSession(val)
              setIsVaxxedOpen(true)
            }}
            screenToLocation={screenToLocation}
            locationFilter={locationFilter}
          />
        </div>
      </div>
      <Modal
        className={styles.ticketBookingModal}
        open={isTicketOpen}
        handleClose={() => setIsTicketOpen(false)}
      >
        {isPreview ? (
          <TicketBookingPreview
            className={styles.ticketBooking}
            event={event}
            alwaysShowTitle={true}
          />
        ) : showTicketBooking && veeziWebSessions.length ? (
          <TicketBooking
            className={styles.ticketBooking}
            event={event}
            veeziWebSessions={veeziWebSessions}
            alwaysShowTitle={true}
          />
        ) : (
          <div className="flex w-full h-full items-center justify-center py-16 px-10">
            {noSessionMessage ? (
              <div dangerouslySetInnerHTML={{ __html: noSessionMessage }} />
            ) : (
              <h2 className="text-4xl">No tickets available.</h2>
            )}
          </div>
        )}
      </Modal>
      <Modal
        className={styles.vaxxedModal}
        backgroundClassName={styles.vaxxedModalBackground}
        closeClassName={styles.vaxxedModalClose}
        open={isVaxxedOpen}
        handleClose={() => setIsVaxxedOpen(false)}
      >
        <div className={styles.vaxxedModalWrapper}>
          <p>Only patrons who meet any ONE of the following criteria will be allowed to this screening:</p>
          <ul>
            <li>1. Fully vaccinated</li>
            <li>2. Recovered from COVID-19 within the last 180 days after infection with valid memo.</li>
            <li>3. Medically ineligible for all COVID-19 vaccines with valid doctor-certified memo.</li>
            <li>4. Children aged 12 years and below (i.e. born in or after 2010)</li>
          </ul>
          <p>By clicking CONFIRM, you agree that you and all members of your group meet any of the criteria above.</p>
          {selectedSession &&
            <Link className={`${styles.btnModalConfirm} block btn-primary relative`}
              to={selectedSession.Url}
              target="_blank">
              CONFIRM
            </Link>
          }
        </div>
      </Modal>
    </>
  )
}

FilmListItem.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string,
  itemStyle: PropTypes.oneOf(['card', 'row']),
  extraFilter: PropTypes.func,
}

export default FilmListItem
