import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { flatten } from '../util/array'

import { FilmListTemplate } from '../templates/film-list'

const MovieShowtimesPage = ({ data, location }) => {
    const films = data.films.edges.map(e => ({
        ...e.node.frontmatter,
        ...e.node.fields,
        themes:
            e.node.fields.themes &&
            e.node.fields.themes.map(c => ({
                ...c.fields,
                ...c.frontmatter,
            })),
        categories:
            e.node.fields.categories &&
            e.node.fields.categories.map(c => ({
                ...c.fields,
                ...c.frontmatter,
            })),
    })).sort((a, b) => {
        if (a.order === b.order) {
            return a.title.localeCompare(b.title)
        }
        return (a.order !== null ? a.order : Infinity) - (b.order !== null ? b.order : Infinity)
    })

    const categories = data.categories.edges.map(c => ({
        ...c.node.frontmatter,
        ...c.node.fields,
    }))

    const themes = data.themes.edges.map(c => ({
        ...c.node.frontmatter,
        ...c.node.fields,
    }))

    const venues = data.venues.edges.map(c => ({
        ...c.node.frontmatter,
        ...c.node.fields,
    }))

    const locations = data.locations.edges.map(e => ({
        ...e.node.frontmatter,
        screens: Array.from(
            new Set(
                flatten(
                    (e.node.fields.venues || []).map(v =>
                        (v.frontmatter.screens || []).map(s => s.veeziScreenId)
                    )
                )
            )
        ),
        venues: (e.node.fields.venues || []).map(e => ({ ...e.frontmatter })),
    }))

    const searchIndex = data.searchIndex && data.searchIndex.index

    return (
        <>
            <FilmListTemplate
                films={films}
                locations={locations}
            />
        </>
    )
}
const ContentShape = PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                node: PropTypes.shape({
                    frontmatter: PropTypes.object,
                }),
            })
        ),
    }),
})


MovieShowtimesPage.propTypes = {
  data: PropTypes.shape({
    films: ContentShape,
    locations: PropTypes.object,
  }),
}

export default MovieShowtimesPage

export const pageQuery = graphql`
  query FilmList {
    films: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "films-and-events" } }
        frontmatter: { eventTypes: { in: ["film"] } }
      }
      sort: { fields: [frontmatter___order, frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            ...EventFieldWithoutVenue
          }
          frontmatter {
            ...EventFrontmatter
          }
        }
      }
    }
    themes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "themes" } } }
    ) {
      edges {
        node {
          ...AllThemes
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "categories" } }
        frontmatter: { appliesTo: { in: ["films"] } }
      }
    ) {
      edges {
        node {
          ...AllCategories
        }
      }
    }
    locations: allMarkdownRemark(
      filter: { fields: { collection: { eq: "locations" } } }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            veeziToken
          }
          fields {
            venues {
              frontmatter {
                title
                screens {
                  veeziScreenId
                }
                accessibility
              }
            }
          }
        }
      }
    }
    venues: allMarkdownRemark(
      filter: { fields: { collection: { eq: "venues" } } }
    ) {
      edges {
        node {
          ...AllVenues
        }
      }
    }
    searchIndex: siteSearchIndex {
      index
    }
  }
`
