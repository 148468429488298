import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Bling as GPT } from "react-gpt";

import styles from './event-list.module.css'
import styles2 from './film-list-item.module.css'
import FilmListItem from './film-list-item'
import { classes } from '../util/components'

const FilmList = memo(
  ({
    events,
    extraFilter,
    className,
    showEventTypes = false,
    listStyle = 'row',
    type = "other",
    screenToLocation,
    locationFilter,
  }) => {

    const itemStyle = 'row'

    const renderView = []
    events.forEach((item, index) => {
      renderView.push(<FilmListItem
        key={index}
        extraFilter={extraFilter}
        event={item}
        itemStyle={itemStyle}
        className={styles.eventListItem}
        showEventType={showEventTypes}
        screenToLocation={screenToLocation}
        locationFilter={locationFilter}
      ></FilmListItem>)
      if (type === 'ads' && index === 2) {
        renderView.push(<GPT
          key="ads-1"
          style={{
            justifyContent: 'center',
          }}
          className="ads-1"
          adUnitPath="/21684927889/homepage_projector/div-gpt-ad-3302321-1"
          sizeMapping={[
            { viewport: [0, 0], slot: [320, 320] },
            { viewport: [970, 0], slot: [970, 250] },
          ]}
          collapseEmptyDiv={true}
        />)
      }
    })

    return (
      <div data-event='film' className={classes(styles.events, className, styles[listStyle], styles2.filmsSection)}>
        {renderView}
        {/* {events.map((event, i) => (
          <FilmListItem
            key={i}
            extraFilter={extraFilter}
            event={event}
            itemStyle={itemStyle}
            className={styles.eventListItem}
            showEventType={showEventTypes}
          ></FilmListItem>
        ))} */}
      </div>
    )
  }
)

FilmList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  listStyle: PropTypes.oneOf(['grid', 'list']),
  showEventTypes: PropTypes.bool,
  extraFilter: PropTypes.func,
  screenToLocation: PropTypes.object,
  locationFilter: PropTypes.string,
}

export default FilmList
